export default [
  {
    header: 'User Management',
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'user-list',
    children: [
      {
        title: 'Users',
        route: 'users',
        action: 'view',
      },
      {
        title: 'Employees',
        route: 'employees',
        action: 'view',
      },
      {
        title: 'Departments',
        route: 'departments',
        action: 'view',
      },
    ],
  },
]
