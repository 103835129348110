export default [
  {
    header: 'Accounts',
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    route: 'accounts',
    children: [
      {
        title: 'Invoice',
        route: 'invoices',
        // resource: 'flight_reservation',
        action: 'view',
      },
    ],
  },
  {
    title: 'Wallet',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Deposit',
        route: 'deposit',
      },
      {
        title: 'Credit',
        route: 'credit',
      },
    ],
  },
]
