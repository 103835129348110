export default [
  {
    header: 'All Reports',
  },
  {
    title: 'Reports',
    icon: 'PieChartIcon',
    route: 'reports',
    children: [
      {
        title: 'Report',
        // route: 'report',
        // resource: 'bus_reservation',
        action: 'view',
      },
    ],
  },
]
